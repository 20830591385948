import React from "react";

import './ContactUs.css'

import ContactInformation from '../../../shared/components/ContactInformation/ContactInformation';
import ContactForm from '../ContactForm/ContactForm';


const ContactUs = ({props}) => {

    const contactInformation ={
        contactInformationTitle : "Have a query?",
        sectionName : 'contact',
        contactInformationWeek : "Monday - Friday 9:00 AM - 09:00 PM EST",
        contactInformationWeekend : "Saturday - Sunday 9:00 AM - 05:00 PM EST"
    }

    return (
        <section className="ContactUs__main-container" id="contact-us" >
                <div className="ContactUs__contact-information-container">
                    <div className="ContactUs__contact-information">
                       <ContactInformation props={contactInformation} />
                    </div>
                </div>
                <div className="ContactUs__contact-form-container">
                    <div className="ContactUs__contact-form">
                        <ContactForm></ContactForm>
                    </div>
                </div>  
        </section>
    );
};

export default ContactUs;