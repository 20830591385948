import React from "react";
import './ProductDescription.css';

const ProductDescription = ({ props }) => {

    let paragraphContent = (
        <>
            <p>
                <b>Stay on top of the latest trends in fashion, beauty, business,
                    and more. </b> Our membership plan gives you access to exclusive
                magazines at a better price than ever before.
            </p>
            <p>
                Choose up to four different titles to have delivered to your
                home, if there is a specific magazine you want and it is not in
                our selection, simply call Customer Service and we will get it
                for you.
            </p>
        </>
    );

    return (
        <section className="ProductDescription__main-container" id="about-us">
            <div className="ProductDescription__explanation-container">
                <div className="ProductDescription__title-container">
                    <h1>{props.title}</h1>
                </div>
                <div className="ProductDescription__explanation-container text-left">
                    {paragraphContent}
                </div>
            </div>

            <div className="ProductDescription__img-container">
                <div className="ProductDescription__img-inner-container">
                    <img src={props.img} alt={props.title} className="ProductDescription__img" />
                </div>
            </div>
        </section>
    );
};

export default ProductDescription;