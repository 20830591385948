import { DATA_COMES_FROM } from "../constants/formValue";
const validate = {

    emailAddress: (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (email.trim() === '') {
            return {
                isValid: false,
                theError: "Email cannot be empty"
            };
        }
        else if (!(email !== '' && /^[^\d]/.test(email))) {
            return {
                isValid: false,
                theError: "The email must not start with numbers"
            };
        } else if (!emailRegex.test(email)) {
            return {
                isValid: false,
                theError: "It is missing: @, a dot (.), and other characters, for a valid email"
            };
        } 
       
        else {
            return {
                isValid: true,
                theError: ""
            };
        }
        
    },


    nameOrLastname: (name, TypeOfattribute) => {
        const nameRegex = /^[A-Za-z\s]+$/;
        if ( name.trim() === ''){
            return{
                isValid : false,
                theError: TypeOfattribute === 'firstName' ? 'First name cannot be empty' : 'Last name cannot be empty'
            }
        }
        
        else if( !nameRegex.test(name) ){
            return{
                isValid : false,
                theError: TypeOfattribute === 'fname' ? "It doesn't have valid characters for a name" : "It doesn't have valid characters for a last name"
            }
        }
        return{
            isValid : true,
            theError: ""
        }
    },

    phoneNumber: (phoneNumber) => {
        const phoneNumberRegex = /^[0-9 ()]+$/;


        if( phoneNumber.trim() === ''){
            return{
                isValid : false,
                theError: 'Phone number cannot be empty'
            }
        }

        else if (!phoneNumberRegex.test(phoneNumber)) {
            return{
                isValid : false,
                theError: "This field only accepts numbers"
            }
        }

        else if( phoneNumber.length <10 || phoneNumber.length >=11){
            return{
                isValid : false,
                theError: "The phone number must be 10 digits"
            }
        }


        return {
            isValid : true,
            theError : ''
        };
    },

    isEmptyField: (field, TypeOfattribute) => {

        const attributeName = TypeOfattribute === 'city' ? 'City' : 
                              TypeOfattribute === 'zipCode' ? 'Zip code' :
                              TypeOfattribute === 'state' ? 'State':
                              TypeOfattribute === 'address' || TypeOfattribute === 'addressApt' ? 'Address':
                              TypeOfattribute === 'message' ? 'Message' :
                              TypeOfattribute === 'orderId' ? 'Order id':
                              TypeOfattribute === 'reasonForCancellation' ? 'reason' :
                              TypeOfattribute === 'lastName' ? 'Last name' :
                              TypeOfattribute === 'firstName' ? 'First name' : 'field';


        const numberRegex = /^[0-9]+$/;
        if(TypeOfattribute === 'zipCode'){
            if (!field.length){
                return {
                    isValid : false,
                    theError : `Zip code cannot be empty`
                };
               
            }
            else if ( !numberRegex.test(field) ) {
                return {
                    isValid : false,
                    theError : `Zip code only accepts numbers`
                };  
            }
        }
        else{
            if (!field.length){
                return {
                    isValid : false,
                    theError : `${attributeName} cannot be empty`
                };
            }
        }
        

        return {
            isValid : true,
            theError : ''
        };

    },

    cardNumber: (cardNumber) => {
        const cardNumberRegex = /^[0-9]+$/;

        if( cardNumber.trim() === ''){
            return{
                isValid : false,
                theError: 'Card number cannot be empty'
            }  
        }

        else if (!cardNumberRegex.test(cardNumber) ) {
            return{
                isValid : false,
                theError: "This field only accepts numbers"
            }
        }

        else if(cardNumber.length > 16 ){
            return{
                isValid : false,
                theError: "This field must have 16 digits"
            }
        }


        return {
            isValid : true,
            theError : ''
        };
    },


    

    year: (year) => {
        const numberRegex = /^[0-9]+$/;
        if (!numberRegex.test(year) || year.length > 4) {
            return false;
        }
        return true;

    },

    month: (month) => {
        const numberRegex = /^[0-9]+$/;
        if (!numberRegex.test(month) || (month.length > 2 || month > 12)) {
            return false;
        }

        return true;
    },


    hasCardValidExpirationDate: ( expirationDate ) => {
        let expDate = expirationDate.split("-");
        const expiryMonth = expDate[0];
        const expiryYear = expDate[1];
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; 

       

        if( expiryYear > currentYear.toString().slice(-2)){

            if ( expiryMonth > 12 || expiryMonth < 1) return false;
            
            else return true; 
        }

        else if ( expiryYear === currentYear.toString().slice(-2)){
            if( expiryMonth > currentMonth && (  expiryMonth > 0 && expiryMonth <= 12) ) {
                return true;
            }
            else{
                return false;
            }
        }


        else{
            return false;
        }

    },






    cvv: (cvv) => {

        const cvvRegex = /^[0-9]+$/;
        if( cvv.trim() === ''){
            return{
                isValid : false,
                theError: 'cvv cannot be empty'
            }  
        }
        else if(!cvvRegex.test(cvv)){
            return{
                isValid : false,
                theError: "This field only accepts numbers"
            }
        }

        else if(cvv.length < 3 ){
            return{
                isValid : false,
                theError: "The cvv must have 3 digits"
            }
        }

        else if (cvv.length > 3 ){
            return{
                isValid : false,
                theError: "The cvv must not have more than 3 digits"
            }
        }
        return {
            isValid : true,
            theError : ''
        }; 
        
    },


    requieredFieldsToSentData(comesFrom, form) {

        let fields;
        switch (comesFrom) {
            case DATA_COMES_FROM.CHECKOUT_FORM:
                fields = ['cardNumber', 'fcvv', 'expDate', 'firstName', 'city', 'zipCode', 'email', 'lastName', 'state', 'address', 'phoneNumber'];
                break;

            case DATA_COMES_FROM.CONTACT_FORM:
                fields = ['firstName', 'phoneNumber', 'email', 'message'];
                break;

            case DATA_COMES_FROM.CANCEL_SERVICE_FORM:
                fields = ['orderId', 'phoneNumber', 'reasonForCancellation'];
                break;

            default:
                fields = null;
                break;
        }

        const areRequiredFieldsFilled = fields.every(field => {
            const value = form[field];
            return value !== undefined && value !== null && value.trim() !== '';
          });

        if (areRequiredFieldsFilled) {
            return true;
        }

        return false;
    },


    identifyCardType(cardNumber) {
        const numberOnly = cardNumber.replace(/[\s-]/g, '');

        if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(numberOnly)) {
            return "Visa";
        } else if (/^5[1-5][0-9]{14}$/.test(numberOnly)) {
            return "Mastercard";
        } else if (/^3[47][0-9]{13}$/.test(numberOnly)) {
            return "American Express";
        } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(numberOnly)) {
            return "Discover";
        } else {
            return "Other payment method";
        }
    },




    allFieldsBeforeTheCheckout: (formData, formFrom) => {
        let regex;
        const theErrors = [{
            isValid: true,
            typeError: ''
        }];
        if (formFrom === DATA_COMES_FROM.CONTACT_FORM) {
            for (const theKey in formData) {
                if (theKey === 'firstName') {
                    regex = /^[A-Za-z\s]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The name only accepts letters, no numbers' })
                    }

                }

                else if (theKey === 'message') {
                    /* regex = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-]+$/g; */
                    /* if (!regex.test(formData[theKey])) { */
                    if (formData[theKey].lenght === 0) {

                        theErrors.push({ isValid: false, typeError: 'The message field cannot be empty' })
                    }

                }

                else if (theKey === 'phoneNumber') {
                    regex =  /^[0-9 ()]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The phone number only accepts numbers' })
                    }
                    else if (formData[theKey].length > 10 || formData[theKey].length < 10) {
                        theErrors.push({ isValid: false, typeError: 'The phone number must be 10 digits' })
                    }

                }
                else if (theKey === 'email') {
                    regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The email does not have a valid characters' });
                    }
                }

            }
        }
        else if (formFrom === DATA_COMES_FROM.CANCEL_SERVICE_FORM) {


            for (const theKey in formData) {
                if (theKey === 'orderId') {
                    regex = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-]+$/g;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The order ID should not have any special characters (white spaces)' })
                    }

                }

                else if (theKey === 'phoneNumber') {
                    regex =  /^[0-9 ()]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The phone number only accepts numbers' })
                    }
                    else if (formData[theKey].length > 10 || formData[theKey].length < 10) {
                        theErrors.push({ isValid: false, typeError: 'The phone number must be 10 digits' })
                    }

                }
                else if (theKey === 'reasonForCancellation') {

                    if (formData[theKey].length === 0) {

                        theErrors.push({ isValid: false, typeError: 'The email does not have a valid characters' });
                    }
                }

            }


        }
        else {


            for (const theKey in formData) {
                if (theKey === 'cardNumber') {
                    regex =/^[0-9]+$/;
                     if ( !regex.test( formData[theKey])){
                        theErrors.push({isValid: false, typeError: 'The card number only accepts numbers '})
                    }


                    else if ( formData[theKey].length > 16 || formData[theKey].length <16 ){
                        theErrors.push({isValid: false, typeError: 'The card number must be 16 digits '})
                     }

                }
                else if (theKey === 'fcvv') {
                    regex = /^[0-9]+$/;
                    if ( !regex.test( formData[theKey])){
                        theErrors.push({isValid: false, typeError: 'The cvv only accepts numbers '})
                    }
    
                    else if( formData[theKey].length <3 || formData[theKey].length >3 ){
                        theErrors.push({isValid: false, typeError: 'The cvv must be 3 digits'})
                    }
                }
                else if (theKey === 'expDate') {
                    regex = /^[0-9-]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The expiration date only accepts numbers and - ' })
                    }
                    else if( formData[theKey].length >5){
                        theErrors.push({ isValid: false, typeError: 'There are more than 5 characters in the exp date' })
                    }

                }
                else if (theKey === 'firstName') {
                    regex = /^[A-Za-z\s]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The first name only accepts numbers' })
                    }
                }
                else if (theKey === 'city') {
                    
                    if (formData[theKey].length === 0) {

                        theErrors.push({ isValid: false, typeError: 'The city should be not empty' });
                    }
                }
                else if (theKey === 'zipCode') {
                    regex = /^[0-9]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The zip code only accepts numbers' });
                    }
                }
                else if (theKey === 'email') {
                    regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The email does not have a valid characters' });
                    }
                }

                else if (theKey === 'lastName') {
                    regex =  /^[A-Za-z\s]+$/;
                    if (!regex.test(formData[theKey])) {

                        theErrors.push({ isValid: false, typeError: 'The last name only accepts letters' })
                    }
                }
                else if (theKey === 'state') {
                    
                    if (formData[theKey].lenght) {

                        theErrors.push({ isValid: false, typeError: 'The state hould be not empty' });
                    }
                }
                else if (theKey === 'address') {
                    
                    if (formData[theKey].lenght === 0) {

                        theErrors.push({ isValid: false, typeError: 'The address should be not empty' });
                    }
                }
                else if (theKey === 'phoneNumber') {
                    regex = /^[0-9 ()]+$/;
                    if ( !regex.test( formData[theKey])){
                   
                        theErrors.push({isValid: false, typeError: 'The phone number only accepts numbers'})
                    }
                    else if ( formData[theKey].length >10 ||  formData[theKey].length  < 10){
                        theErrors.push({isValid: false, typeError: 'The phone number must be 10 digits'})
                    }
                }

            }






        }

        return theErrors;

    },

    cleanSpaces : (formData) => {
        const cleanObjet = {};
    
        for (const clave in formData) {
            if (typeof formData[clave] === 'string') {
             
                cleanObjet[clave] = formData[clave].trim();
            } else {
                cleanObjet[clave] = formData[clave];
            }
        }
    
        return cleanObjet;
    },



}

export default validate;