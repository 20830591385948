import React from "react";
import './WelcomeSection.css';

const WelcomeSection = ( {props} ) => {

    const bannerTitle = window.location.pathname === '/contact-us' ?
    'CONTACT US' :
    window.location.pathname === '/cancel-service' ?
        'CANCEL' :
        window.location.pathname === '/refund-policy' ?
            'HOW TO GET A REFUND' :
            window.location.pathname === '/terms-of-service' ?
                'KNOW THE TERMS OF SERVICE' :
                window.location.pathname === '/privacy-policy' ?
                    'HOW WE USE YOUR DATA' : 'ERROR 404';


return (
    <>
        {
            (window.location.pathname === '/' ||
                window.location.pathname === '/about-us' ||
                window.location.pathname === '/price' || 
                window.location.pathname === '/checkout') ? (
                <section className="WelcomeSection__main-section-container" style={{ backgroundImage: `url(${props.lengendBackground})` }} id="home">
                    <div className="WelcomeSection__onbg-img-container">
                        <img src={props.cardImg} alt="onbg1" className="WelcomeSection__onbg-img" />
                    </div>
                    <CardInfo props={props}></CardInfo>
                </section>
            ) : ( window.location.pathname === '/contact-us' || window.location.pathname === '/refund-policy' || window.location.pathname === '/terms-of-service' || window.location.pathname === '/privacy-policy') ? (<section className="WelcomeSection__main-section-container-banner" style={{ backgroundImage: `url(${props.lengendBackground})` }} id="home">
            <div className="WelcomeSection__banner-title-container">
                <h1 className="WelcomeSection__banner-title">{bannerTitle}</h1>
            </div>
        </section>) : (
            <section className="WelcomeSection__main-section-container-banner" style={{ backgroundImage: `url(${props.lengendBackground})` }} id="home">
            <div className="WelcomeSection__banner-title-container">
                <h1 className="WelcomeSection__banner-title">{bannerTitle}</h1>
            </div>
        </section>
        )
        }
    </>

);
};


const CardInfo = ( { props }) =>{
    const phoneNumber = "8884743416";
    const goToHandle = (sectionId) => {
        if( window.location.pathname === '/privacy-policy' ||window.location.pathname === '/terms-of-service' || window.location.pathname === "/refund-policy" ){
            window.location.href = `tel:${phoneNumber}`;
        }
        else if( window.location.pathname === '/'){
            const sectionElement = document.getElementById(sectionId);
            const windowHeight = window.innerHeight;
            const docScrollTop = window.scrollY || document.documentElement.scrollTop;
            const sectionTop = sectionElement.getBoundingClientRect().top + docScrollTop;
            const centeredPosition = sectionTop - (windowHeight / 2);
            const scrollToPosition = centeredPosition + 280; 
            window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
        }
    }
    
    
    return(
       
        <div className="WelcomeSection__main-card-info-container">

            <div className="WelcomeSection__main-title-container">
                <h2 className="text-left">{props.cardTitle}</h2>
            </div>
            <div className="WelcomeSection__main-body-container">
                <p>
                {props.cardBodyText}
                </p>
            </div>
            {
                props.cardButtonText.trim() !== "" ? (
                    <div className="WelcomeSection__main-button-container">
                        <div className="WelcomeSection__inner-button-container">
                            <a href="" onClick={(e) => { e.preventDefault(); goToHandle('contact-us')}}>
                                {props.cardButtonText}
                            </a>
                        </div>
                    </div>
                ) : (
                    <div className="WelcomeSection__button-container" style={{ minWidth : '10em'}}>
                    </div>
                )
            }
        </div>
     
    )
}


export default WelcomeSection;