import React, { useState } from 'react';
import { Link } from 'react-router-dom';



import './Header.css';

import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from '../UIElements/Backdrop';
import NavegationLinks from './components/NavegationLinks/NavegationLinks';
import Header from './components/Header/Header';

//assets
import magazinesLoversLogo from '../../../utils/assets/logo/magazines-lovers-logo.png';
import facebookIcon from '../../../utils/assets/header/facebook-icon.png';
import instagramIcon from '../../../utils/assets/header/instagram-icon.png';

const MainNavigation = props => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const handleGoTo = () =>{
    
    const sectionElement = document.getElementById('home');
    console.log(sectionElement)
    sessionStorage.setItem('sectionName', 'home');
    const windowHeight = window.innerHeight;
    const docScrollTop = window.scrollY || document.documentElement.scrollTop;
    const sectionTop = sectionElement.getBoundingClientRect().top + docScrollTop;
    const centeredPosition = sectionTop - (windowHeight / 2);
    const scrollToPosition = centeredPosition + 310; 
    window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
   
  }


  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavegationLinks />
        </nav>
      </SideDrawer>

      <Header>
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
        <h1 className="main-navigation__title">
          <a href='/' onClick={handleGoTo}><img src={magazinesLoversLogo} alt='magazinesLoversLogo' className='Navigation__logo'></img></a>
        </h1>
        <nav className="main-navigation__header-nav">
          <NavegationLinks />
        </nav>
        <div className='MainNavigation__main-social-media-container'>
          {/* <div className='MainNavigation__inner-social-media-container'>
            <a href='https://www.facebook.com/Magazines.24.7.llc' target='_blank'><img src={facebookIcon} alt='instagram-icon' style={{marginRight : '.3em'}}></img></a>
            <a href='https://www.instagram.com/magazines24_7/' target='_blank'><img src={instagramIcon} alt='facebook-icon' style={{marginRight : '.3em'}}></img></a>
          </div> */}
        </div>


      </Header>
    </React.Fragment>
  );
};

export default MainNavigation;
