import React from "react";

import './SectionInfo.css';




const SectionInfo = (props) => {

    return(
    <section className="SectionInfo__main-section-container">
        <div className="SectionInfo__title-container" style={{backgroundColor : `${props.backgroundColor}`}}>
            <h4 className="SectionInfo__title" style={{ color : `${props.textColor}`}} >{props.title}</h4>
        </div>
    </section>
    );
};

export default SectionInfo;