import CryptoJS from "crypto-js";

export  const encryptData = ( formData ) =>{
    const iv = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);
    const keyBytes = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const ivBytes = CryptoJS.enc.Hex.parse(iv);

    const encrypt = CryptoJS.AES.encrypt(JSON.stringify(formData), keyBytes, {
        iv: ivBytes, 
        padding: CryptoJS.pad.Pkcs7
      }).ciphertext.toString(CryptoJS.enc.Base64);

    return {
      encrypt, 
      iv
    };
}