import React, { useEffect } from "react";

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import unsubscribeBannerImg from '../../utils/assets/shared/bgImg.png';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import Cancelation from '../components/Cancelation/Cancelation';

 

const CancelService = props => {
     const legendInfo =
     {
         cardTitle: "Are you thinking about cancelling your current subscription?",
         cardBodyText: ``,
         cardButtonText: "",
         lengendBackground: unsubscribeBannerImg
     }

     useEffect(()=>{
        window.scroll(0,0);
     })

    return (
        <React.Fragment>
            <WelcomeSection props={legendInfo}/>
            <NavegationInfo page="Cancel service"/>
            <Cancelation/>
            
            
        </React.Fragment>
    );
};

export default CancelService;