import React, { useEffect } from "react";

import refundPolicyLegen from '../../utils/assets/shared/bgImg.png';
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";


import ReimbursementGuidelines from "../components/ReimbursementGuidelines/ReimbursementGuidelines";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";




const RefundPolicy = props => {
    const bannerSectionInfo =
    {
        cardTitle: "Do you have a problem with an item?",
        cardBodyText: ``,
        cardButtonText: "Call us",
        lengendBackground: refundPolicyLegen
    }

    useEffect(()=>{
        window.scroll(0,0);
     })

    return (
        <React.Fragment>
         
            <WelcomeSection props={bannerSectionInfo}/>
            <NavegationInfo page="Refund policy"/>
            <ReimbursementGuidelines />
       
            
           
            
        </React.Fragment>
    );
};

export default RefundPolicy;