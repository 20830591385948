import React from "react";
import './SubscriptionDescription.css';

const SubscriptionDescription = ({ props }) => {

    const bodyDescription  = (
        <p>
            Stay on top of business issues, the latest fashion trends, 
            beauty tips, or useful parenting advice with these incredible
            benefits.
        </p>
    )
    return (
        <div className="SubscriptionDescription__main-container" >
            

                <div className="SubscriptionDescription__img-container">
                    <div className="SubscriptionDescription__inner-img-container">
                        <img src={props.img} alt={props.imgDescription} className="SubscriptionDescription__img"/>
                    </div>
                </div>
            
            <div className="SubscriptionDescription__title-container">
                <p>
                    {props.title}
                </p>
            </div>
            <div className="SubscriptionDescription__description-container">
            
                {bodyDescription}
            
            </div>
        </div>
    );
};

export default SubscriptionDescription;