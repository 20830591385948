import React, { useState } from "react";

import './Faq.css';

import Accordion from './components/Accordion/Accordion';
import ContactCard from '../../../shared/components/ContactCard/ContactCard';



const Faq = props => {

    const faqsInformation =
        [
            {
                question: "How many magazines do I get with my subscription?",
                answer:
                    "A lot.",
                open: false
            },
            {
                

                question:
                    "Can I change my address in the event of a move?",
                answer: "This many!",
                open: false
            },
            {
                question: "Where are my billing details?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question:
                    "Can I trade one magazine in ti another?",
                answer: "This many!",
                open: false
            },
            {
                question:
                    "What magazines can I buy?",
                answer: "This many!",
                open: false
            },
            {
                question:
                    "How do I cancel?",
                answer: "This many!",
                open: false
            }

            


            
            
        ]

    const [faqs, setFaqs] = useState(faqsInformation)

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <>
        {/* <section className="Faq__main-container" id="faq">
            <h1 className="Faq__main-container__main-title">FQA</h1>
            <div className="Faq__faqs-container">   
                <div className="Faq__faqs columns faqs">
                    {faqs.map((faq, index) => (
                        <Accordion faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}></Accordion>
                    ))}
                </div>
            </div>
        </section> */}
        <section>
            <div className="Faq__customer-service-container">
                <ContactCard/>
            </div> 
        </section>
        </>

    );
};

export default Faq;