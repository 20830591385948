import "./Loader.css";
const Loader = ({ isLoading }) => {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    visibility: isLoading ? 'visible' : 'hidden',
    opacity: isLoading ? 1 : 0,
    transition: 'opacity 0.3s, visibility 0.3s',
    

  };
  return (
    <div style={overlayStyle}>
      <div className="LoadingTitle" ><h1>Loading...</h1></div>
      <div className="loading">
        <div className="loader"></div>
        <div className="loader"></div>
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default Loader;